import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthService } from '../auth.service';
import { UserModel, UserStatus } from "../dtos";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class UserStoreService {
  private statusSubject = new BehaviorSubject<UserStatus>(null);

  status$ = this.statusSubject.asObservable();

  constructor(
    private userService: UserService,
    private authService: AuthService
  ) {
    userService.getUserInfo().subscribe(userInfo => {
      this.statusSubject.next(userInfo.status);
    });
  }

  createUser(): Observable<UserModel> {
    const email = this.authService.email;
    const obs$ = this.userService.createUser(email);

    obs$.subscribe(() => {
      this.statusSubject.next('active');
    });

    return obs$;
  }
}
