import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from './app/material.module';
import { TranslocoRootModule } from './app/transloco-root.module';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CoreModule } from './app/core.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { firstTimeUsersProvider } from './app/first-time-user/first-time-user.interceptor';
import { OidcSecurityService, provideAuth } from 'angular-auth-oidc-client';
import { filter, firstValueFrom, of, switchMap, tap } from 'rxjs';
import { AuthService } from './app/auth.service';
import { authConfig } from './app/auth.config';

if (environment.production) {
  enableProdMode();
}

// After signup we are logged in but local auth service is unaware, clear any state and trigger a fresh authorize flow.
export function signupInitializerFactory(oidcAuthService: OidcSecurityService): () => Promise<unknown> {
  return () => firstValueFrom(oidcAuthService.getState().pipe(
    switchMap(state => {
      if (state !== 'signup') {
        return of(null);
      }

      removeQueryParam('code');
      removeQueryParam('state');
      return oidcAuthService.setState('').pipe(
        tap(() => oidcAuthService.authorize())
      );
    })
  ));
}

// Unless we are loading the page from a signup flow, check authentication state and request an auth cookie if we are authenticated.
export function authInitializerFactory(oidcAuthService: OidcSecurityService, authService: AuthService): () => Promise<unknown> {
  return () => firstValueFrom(oidcAuthService.getState().pipe(
    filter(state => state !== 'signup'),
    switchMap(() => oidcAuthService.checkAuth().pipe(
      switchMap(loginResponse => loginResponse.isAuthenticated ? authService.cookieAuth(loginResponse.idToken) : of(null))
    ))
  ));
}

function removeQueryParam(param: string) {
  const url = new URL(window.location.href);
  url.searchParams.delete(param);
  window.history.replaceState({}, document.title, url.toString());
}

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideAuth({
      config: authConfig
    }),
    { provide: APP_INITIALIZER, useFactory: signupInitializerFactory, deps: [OidcSecurityService], multi: true },
    { provide: APP_INITIALIZER, useFactory: authInitializerFactory, deps: [OidcSecurityService, AuthService], multi: true },
    importProvidersFrom(BrowserModule, CoreModule.forRoot(), AppRoutingModule, TranslocoRootModule, MaterialModule, FormsModule, ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })),
    firstTimeUsersProvider,
    provideAnimations()
  ]
})
  .catch(err => console.error(err));
